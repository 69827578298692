<script setup>
    import Dropdown from 'primevue/dropdown';
    import MultiSelect from 'primevue/multiselect';

    import PendingToSign from '../PendingToSignApp/PendingToSignApp.vue';
    import ShovelApp from '../ShovelApp/ShovelApp.vue';
</script>

<script>
export default {
  components: {
    Dropdown,
    MultiSelect
  },
  data: function () {
    return {
        currentApp: null,
        selectedApp: null,
        showSelectBtn: true,

        shovelAppStuff: {
          loadingLocationsAvailable: [
            {name: 'Unspecified location', code: ''}
          ],
          filters: {
            loadingLocations: []
          }
        }
    }
  },
  beforeMount() {
    
  },
  mounted() {
    
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    this.currentApp = user.currentApp.code;
    this.showSelectBtn = !(user.plants.length == 1 && user.accessApps.length == 1);
  },
  computed: {
    console: () => console,
  },
  methods: {
    goTo(route) {
      if (route) this.$router.push(route);
    },
    logout() {
      sessionStorage.removeItem('user');
      this.goTo('/login')
    }
  }
}
</script>

<template>
    <div class="app-content data-list orders">
        <div ref="header" class="app-content__header">
            <div class="row">
                <img src="img/logo.svg" alt class="app-header__logo" />
                
                <div class="fulscreen-header-title">
                    <lh-button v-if="showSelectBtn" size="large" type="primary" @click="goTo('/select-plant-app')">{{ $t('Plant & App') }}</lh-button>
                    <lh-button v-if="!showSelectBtn" size="large" icon="sign-in" type="primary" @click="logout()">{{ $t('Logout') }}</lh-button>

                    <lh-input v-if="currentApp == 'pendingToSign'" type="search" style="margin-left: 20px;" :placeholder="$t('Search')" v-lhevent:lhInput="searchTable" />
                    <MultiSelect v-if="currentApp == 'shovelApp'" id="Loading Location" style="margin-left: 20px; width: 280px;" :maxSelectedLabels="1" placeholder="Filter by Loading Location" :options="shovelAppStuff.loadingLocationsAvailable" optionLabel="name"
                      v-model="shovelAppStuff.filters.loadingLocations" aria-label="Options" :showToggleAll="true"
                      selectionMode="multiple">
                    </MultiSelect>
                </div>

            </div>
        </div>
        <div class="app-content__content">
            <PendingToSign v-if="currentApp == 'pendingToSign'"/>
            <ShovelApp v-if="currentApp == 'shovelApp'" :filters="shovelAppStuff.filters" :loadingLocationsAvailable="shovelAppStuff.loadingLocationsAvailable"/>
        </div>
    </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';
@use '@/assets/css/hodim/layout/data-list/ordercard';
@use '@/assets/css/hodim/layout/data-list/multiselect';
@import '@/assets/css/hodim/layout/data-list/calendar';

.fulscreen-header-title {
    display: flex;
    align-items: center;
    justify-content: start;
}

</style>
